<template>
  <span class="tag">
    {{ value }}
  </span>
</template>

<script>
  export default {
    name: 'Tag',
    props: {
      value: {
        type: String,
        required: true
      }
    }
  }
</script>
