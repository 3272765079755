<template>
  <header class="main-header">
    <div class="container container-wide">
      <h1>
        <router-link to="/" title="The App Capital">
          <img class="logo" src="@/assets/img/logo.svg" title="The App Capital">
        </router-link>
      </h1>
      <nav>
        <ul class="main-menu">
          <li>
            <a class="button is-size-5" href="https://blog.theappcapital.com">Blog</a>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
  import ScrollReveal from 'scrollreveal';

  export default {
    name: 'MainHeader',
    mounted () {
      ScrollReveal().reveal('.main-header', {
        distance: '5%',
        origin: 'bottom',
        duration: 1000,
      });
    }
  }

</script>
