<template>
  <section id="development-stack" class="section">
    <div class="container">
      <div class="section-header">
        <h2 class="section-header-title is-size-4">Development Stack</h2>
      </div>
      <div class="columns">
        <div class="column is-two-thirds">
          <div class="columns">
            <div class="column stack-list">
              <h3 class="stack-list-title">Mobile App Development</h3>
              <ul>
                <li>+ iOS</li>
                <li>+ Android</li>
                <li>+ React Native</li>
                <li>+ Java</li>
                <li>+ Kotlin</li>
                <li>+ Swift</li>
              </ul>
            </div>
            <div class="column stack-list">
              <h3 class="stack-list-title">Web Development</h3>
              <ul>
                <li>+ React</li>
                <li>+ VueJS</li>
                <li>+ Laravel</li>
                <li>+ HTML5</li>
                <li>+ SCSS</li>
                <li>+ PHP</li>
              </ul>
            </div>
            <div class="column stack-list">
              <h3 class="stack-list-title">Other</h3>
              <ul>
                <li>+ Agile Sprints</li>
                <li>+ Scrum</li>
                <li>+ Visual Design</li>
                <li>+ Mobile App Design</li>
              </ul>
            </div>
          </div>
        </div>
        <div id="work-together" class="column">
          <hr style="margin-top: 0;">
          <h2 class="title">Let’s work together.</h2>
          <a target="_top" class="button is-size-5" href="mailto:thomasneuteboom@theappcapital.com">Get in touch</a>
        </div>
      </div>
      <hr style="margin-bottom: -2rem;">
    </div>
  </section>
</template>

<script>
  import ScrollReveal from 'scrollreveal'

  export default {
    name: 'DevelopmentStackSection',
    mounted () {
      ScrollReveal().reveal('#development-stack .stack-list, ' +
              '#work-together', {
        distance: '5%',
        origin: 'bottom',
        interval: 130,
        duration: 400,
        delay: 100,
      })
    }
  }

</script>