<template>
  <span>{{ tweeningValue }}</span>
</template>

<script>
  var TWEEN = require('@tweenjs/tween.js')

  export default {
    name: 'AnimatedNumber',
    props: {
      // The value that we'll be tweening to.
      value: {
        type: Number,
        required: true
      },
      // The format after the comma, e.g. if '1' then '10.0'.
      decimals: {
        type: Number,
        default: 0
      },
      // How long the tween should take. (In milliseconds.)
      tweenDuration: {
        type: Number,
        default: 500
      }
    },
    data () {
      return {
        tweeningValue: 0
      }
    },
    mounted () {
      this.initialStart()
    },
    watch: {
      value (newValue, oldValue) {
        this.tween(oldValue, newValue)
      }
    },
    methods: {
      initialStart (defaultValue = 0) {
        this.tween(defaultValue, this.value)
      },
      start () {
        this.tween(this.tweeningValue, this.value)
      },
      // This is our main logic block. It handles tweening from a start value to an end value.
      tween (startValue, endValue) {
        // Handles updating the tween on each frame.
        function animate (time) {
          requestAnimationFrame(animate)
          TWEEN.update(time)
        }

        requestAnimationFrame(animate)

        const tween = new TWEEN.Tween({tweeningValue: startValue})
          .to({tweeningValue: endValue}, 500)
          .onUpdate((object) => {
            this.tweeningValue = object.tweeningValue.toFixed(this.decimals).replace('.', ',')
          })

        tween.start()
      }
    }
  }
</script>