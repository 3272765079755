<template>
  <main>
    <cases-section></cases-section>
    <development-stack-section></development-stack-section>
    <facts-section></facts-section>
  </main>
</template>

<script>
  import FactsSection from './sections/FactsSection'
  import CasesSection from './sections/CasesSection'
  import DevelopmentStackSection from './sections/DevelopmentStackSection'

  export default {
    name: 'Home',
    components: { DevelopmentStackSection, CasesSection, FactsSection},
  }

</script>
