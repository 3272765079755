<template>
  <section id="cases" class="section">
    <div class="container">
      <div class="section-header">
        <span class="section-header-title">Nice to meet you!</span>
        <div class="is-flex">
          <img class="avatar" src="@/assets/img/me.jpg" alt="Thomas Neuteboom"/>
          <h2 class="section-header-subtitle">My name is <a href="https://www.linkedin.com/in/thomas-neuteboom/">Thomas Neuteboom</a>, I'm a freelance mobile app developer. I collaborate with leading brands, create my own products from scratch and set up ventures with external partners. Here's where I showcase some of my work.</h2>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-flex is-one-third">
          <div class="case">
            <div class="case-image-container tilt">
              <img class="case-image-portrait" src="@/assets/img/case_coop_portrait.jpg"/>
            </div>
            <p class="case-body">
              <tag value="iOS"/>
              <tag value="Android"/>
              <br/>
              <strong>Coop</strong> — Maintaining and creating new app features for Coop, a popular grocery store in the Netherlands.
              <br/>
              <small class="has-text-dark has-text-weight-semibold">October 2021 - March 2022</small>
            </p>
          </div>
        </div>
        <div class="column is-flex is-one-third">
          <div class="case">
            <div class="case-image-container tilt">
              <img class="case-image-portrait" src="@/assets/img/case_piggy_portrait.jpg"/>
            </div>
            <p class="case-body">
              <tag value="iOS"/>
              <tag value="Android"/>
              <tag value="From Scratch"/>
              <br/>
              <strong>Piggy</strong> — Piggy is the leading loyalty platform in the Netherlands with thousands of merchants and over 2 million users.
              <br/>
              <small class="has-text-dark has-text-weight-semibold">October 2019 - April 2021</small>
            </p>
          </div>
        </div>
        <div class="column is-flex is-one-third">
          <div class="case">
            <div class="case-image-container tilt">
              <img class="case-image-portrait" src="@/assets/img/case_homies_portrait.jpg"/>
            </div>
            <p class="case-body">
              <tag value="iOS"/>
              <tag value="Android"/>
              <br/>
              <strong>Achmea</strong> — Creating the self-installation service for Homies on Android and iOS.
              <br/>
              <small class="has-text-dark has-text-weight-semibold">September 2018 - August 2019</small>
            </p>
          </div>
        </div>
        <div class="column is-flex is-two-thirds">
          <div class="case">
            <div class="case-image-container case-image-container-wide tilt">
              <img class="case-image-wide" src="@/assets/img/case_sundata_wide.jpg"/>
              <img class="case-image-portrait" src="@/assets/img/case_sundata_portrait.jpg"/>
            </div>
            <p class="case-body">
              <tag value="Website"/>
              <tag value="Front-end"/>
              <tag value="Back-end"/>
              <tag value="From Scratch"/>
              <br/>
              <strong>Sundata</strong> — Software that creates smart insights from solar data and regional weather using a uniquely designed algorithm.
              <br/>
              <small class="has-text-dark has-text-weight-semibold">October 2018 - August 2019</small>
            </p>
          </div>
        </div>
        <div class="column is-flex is-one-third">
          <div class="case">
            <div class="case-image-container tilt">
              <img class="case-image-portrait" src="@/assets/img/case_chewpy_portrait.jpg"/>
            </div>
            <p class="case-body">
              <tag value="iOS"/>
              <tag value="Android"/>
              <tag value="From Scratch"/>
              <br/>
              <strong>Chewpy</strong> — Discovering the best vegetarian recipes using artificial intelligence.
              <br/>
              <small class="has-text-dark has-text-weight-semibold">July 2018 - July 2019</small>
            </p>
          </div>
        </div>
        <div class="column is-flex is-one-third">
          <div class="case">
            <div class="case-image-container tilt">
              <img class="case-image-portrait" src="@/assets/img/case_moneymonk_portrait.jpg"/>
            </div>
            <p class="case-body">
              <tag value="iOS"/>
              <tag value="Android"/>
              <tag value="From Scratch"/>
              <br/>
              <strong>MoneyMonk</strong> — Creating an all-in-one solution for entrepreneurs.
              <br/>
              <small class="has-text-dark has-text-weight-semibold">October 2015 - Present</small>
            </p>
          </div>
        </div>
        <div class="column is-flex is-one-third">
          <div class="case">
            <div class="case-image-container tilt">
              <img class="case-image-portrait" src="@/assets/img/case_stucomm_portrait.jpg"/>
            </div>
            <p class="case-body">
              <tag value="iOS"/>
              <tag value="Android"/>
              <tag value="From Scratch"/>
              <br/>
              <strong>StuComm</strong> — Studentsapp with more than 500.000 active users.
              <br/>
              <small class="has-text-dark has-text-weight-semibold">September 2014 - September 2015</small>
            </p>
          </div>
        </div>
      </div>
      <hr style="margin-bottom: -2rem;">
    </div>
  </section>
</template>

<script>
  import Tilt from 'vanilla-tilt'
  import Tag from '@/components/Tag'
  import ScrollReveal from 'scrollreveal'

  export default {
    name: 'CasesSection',
    components: {Tag},
    mounted () {
      this.$el.querySelectorAll('.tilt').forEach((element) => {
        Tilt.init(element, {max: 5, speed: 300})
      })

      ScrollReveal().reveal('#cases .section-header-title, ' +
        '#cases .section-header-subtitle, ' +
        '#cases .case', {
        distance: '5%',
        origin: 'bottom',
        interval: 130,
        duration: 400,
        delay: 100,
      })
    }
  }

</script>