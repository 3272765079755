<template>
  <footer class="main-footer">
    <div class="container container-wide">
      <div class="social">
        <a class="social-link" href="https://www.linkedin.com/in/thomas-neuteboom/">
          <img src="@/assets/img/linked_in_logo.svg"/>
        </a>
      </div>
      <span>© {{ year }} The App Capital. All rights reserved. KvK: 60859822. Email: thomasneuteboom@theappcapital.com.</span>
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'MainFooter',
    data() {
      return {
        year: new Date().getFullYear()
      }
    }
  }
</script>
