<template>
  <div id="app">
    <main-header/>
    <router-view/>
    <main-footer/>
  </div>
</template>

<script>
  import MainHeader from './components/MainHeader'
  import MainFooter from './components/MainFooter'

  export default {
    components: {MainFooter, MainHeader}
  }
</script>

<style lang="scss">
  @import 'assets/scss/base';
</style>
