<template>
  <section id="facts" class="section">
    <div class="container">
      <div class="columns is-multiline">
        <div class="column">
          <div class="bordered-box" style="min-height: 200px">
            <div class="text-container has-text-centered has-text-vertical-centered">
              <h4 class="title is-size-4">Founded:</h4>
              <span class="is-size-3">2018</span>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="bordered-box" style="min-height: 200px">
            <div class="text-container has-text-centered has-text-vertical-centered">
              <h4 class="title is-size-4">Apps released:</h4>
              <span class="is-size-3"><animated-number :value="25" :decimals="0"/></span>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="bordered-box" style="min-height: 200px">
            <div class="text-container has-text-centered has-text-vertical-centered">
              <h4 class="title is-size-4">Experience:</h4>
              <span class="is-size-3"><animated-number :value="8" :decimals="0"/></span>
              <span class="is-size-5"> years</span>
            </div>
          </div>
        </div>
        <div class="column">
          <div class="bordered-box" style="min-height: 200px">
            <div class="text-container has-text-centered has-text-vertical-centered">
              <h4 class="title is-size-4">Collaboration:</h4>
              <span class="is-size-3"><animated-number :value="16" :decimals="0"/></span>
              <span class="is-size-5"> companies</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import ScrollReveal from 'scrollreveal'
  import AnimatedNumber from '../../components/AnimatedNumber'

  export default {
    name: 'FactsSection',
    components: {AnimatedNumber},
    mounted () {
      ScrollReveal().reveal('#facts .bordered-box', {
        distance: '5%',
        origin: 'bottom',
        interval: 130,
        duration: 400,
        delay: 100
      })
    }
  }

</script>
